import './style.css';

const INITIAL_SIZE = 5;
const MAX_SIZE = 200;
const INITIAL_OPACITY = 1;
const MIN_OPACITY = 0;
const COLORS = [
  { red: 132, green: 94, blue: 194 },
  { red: 214, green: 93, blue: 177 },
  { red: 255, green: 111, blue: 145 },
  { red: 255, green: 150, blue: 113 },
  { red: 255, green: 199, blue: 95 },
  { red: 249, green: 248, blue: 113 },
  { red: 0, green: 129, blue: 207 },
  { red: 0, green: 201, blue: 167 },
];

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * Math.floor(max - min)) + min;
};

class Circle {
  constructor(x, y) {
    let _elem = document.createElement('div');
    let _diameter = INITIAL_SIZE;
    let _opacity = INITIAL_OPACITY;
    let _this = this;
    let _left = 0,
      _top = 0;
    Object.defineProperties(this, {
      diameter: {
        get: function () {
          return _diameter;
        },
        set: function (value) {
          if (value > MAX_SIZE) {
            clearInterval(_timer);
            _elem.remove();
            return;
          }
          _this.opacity =
            1 - (value - INITIAL_SIZE) / (MAX_SIZE - INITIAL_SIZE);
          _diameter = value;
          _elem.style.setProperty('--diameter', `${value}px`);
        },
      },
      opacity: {
        get: function () {
          return _opacity;
        },
        set: function (value) {
          if (value < MIN_OPACITY) return;
          _elem.style.opacity = value;
          _opacity = value;
        },
      },
      left: {
        get: function () {
          return _left;
        },
        set: function (value) {
          _left = value;
          _elem.style.left = `${value}px`;
        },
      },
      top: {
        get: function () {
          return _top;
        },
        set: function (value) {
          _top = value;
          _elem.style.top = `${value}px`;
        },
      },
    });
    _elem.className = 'circle';
    this.diameter = INITIAL_SIZE;
    _this.left = x;
    _this.top = y;
    let color = COLORS[getRandomNumber(0, COLORS.length)];
    _elem.style.setProperty('--red', color.red);
    _elem.style.setProperty('--green', color.green);
    _elem.style.setProperty('--blue', color.blue);
    document.body.appendChild(_elem);
    let _timer = setInterval(function () {
      _this.diameter += 1;
    }, 100);
  }
}

let p = document.createElement('p');
p.innerText = 'Click me!';
document.body.appendChild(p);

window.addEventListener('contextmenu', (evt) => {
  if (evt.ctrlKey) return;
  evt.preventDefault();
  location.reload();
});

let bgColor = COLORS.splice(getRandomNumber(0, COLORS.length), 1)[0];
document.body.style.setProperty(
  '--bg-color',
  `rgb(${bgColor.red}, ${bgColor.green}, ${bgColor.blue})`
);

document.body.addEventListener('mousedown', function (evt) {
  if (evt.button != 0) return;
  let c = new Circle(evt.clientX, evt.clientY);
  p.classList.add('hidden');
});
